import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 20,
    title: "WebSockets con React",
    desc: "Come implementare una Real-Time Communication",
    img: "/blog-image/webSocket.png",
    page: "blog/react-WebSocket",
    data: "25 Set 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "La comunicazione in tempo reale si conferma parte essenziale di molte applicazioni web",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        La comunicazione in tempo reale si
                                        conferma parte essenziale di molte
                                        applicazioni web - che si tratti di chat
                                        in tempo reale, aggiornamenti live su
                                        dati o giochi multiplayer online, la
                                        capacità di fornire informazioni
                                        istantanee agli utenti è fondamentale.
                                        In questo ambito, una delle tecnologie
                                        più utilizzate e potenti per abilitare
                                        la comunicazione in tempo reale è
                                        WebSocket.
                                    </p>
                                    <p>
                                        Vediamo ora come implementare WebSocket
                                        con React così da creare una
                                        comunicazione in tempo reale all’interno
                                        delle web app.
                                    </p>

                                    <h3>Cosa sono i WebSocket?</h3>
                                    <p>
                                        I WebSocket sono un protocollo di
                                        comunicazione full-duplex basato su TCP
                                        che consente la comunicazione
                                        bidirezionale in tempo reale tra un
                                        server e un client. A differenza del
                                        protocollo HTTP tradizionale, in cui il
                                        client deve fare una richiesta al server
                                        per ottenere dati, i WebSocket
                                        permettono una comunicazione istantanea
                                        in cui sia il server che il client
                                        possono inviare messaggi quando
                                        necessario, senza dover attendere una
                                        richiesta.
                                    </p>

                                    <p>
                                        I WebSocket sono ideali per situazioni
                                        in cui è necessario un flusso costante
                                        di dati in entrambe le direzioni, come
                                        chat in tempo reale, aggiornamenti live
                                        su dati o giochi multiplayer.
                                    </p>

                                    <h3>
                                        Implementazione di un server WebSocket
                                    </h3>

                                    <p>
                                        Per prima cosa, risulta necessaria la
                                        configurazione del server WebSocket. In
                                        questo articolo, useremo Node.js e il
                                        framework `ws` per creare un server
                                        WebSocket semplice ma efficace.
                                    </p>
                                    <h5>Installazione delle dipendenze</h5>

                                    <p>
                                        Creiamo una directory per il nostro
                                        progetto e quindi eseguiamo il seguente
                                        comando per inizializzare un progetto
                                        Node.js e installare il modulo 'ws':{" "}
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`mkdir websocket-react-example`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <br />
                                    <MyCoolCodeBlock
                                        code={`cd websocket-react-example`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <br />
                                    <MyCoolCodeBlock
                                        code={`npm init -y`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <br />
                                    <MyCoolCodeBlock
                                        code={`npm install ws`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        Ora, creiamo il nostro server WebSocket
                                        utilizzando Node.js e il modulo `ws`.
                                        Crea un file chiamato `server.js` nella
                                        directory del progetto e aggiungi il
                                        seguente codice:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
const WebSocket = require('ws');
const server = new WebSocket.Server({ port: 8080 });

server.on('connection', (socket) => {
    console.log('Client connected');
    
    socket.on('message', (message) => {
        console.log('Received: $ {message}');
    
        // Ecco dove gestire i messaggi inviati dai client e inviare risposte
        socket.send('You said: $ {message}');
    });
    
    socket.on('close', () => {
        console.log('Client disconnected');
    });
});
                                        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />

                                    <p>
                                        Questo codice crea un server WebSocket
                                        che ascolta sulla porta 8080 e gestisce
                                        le connessioni dei client. Quando un
                                        client si connette, viene registrato un
                                        gestore per i messaggi in arrivo e le
                                        chiusure della connessione.
                                    </p>

                                    <h5>Avvio del server WebSocket</h5>

                                    <p>
                                        Per avviare il server WebSocket, esegui
                                        il seguente comando nella directory del
                                        progetto:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`node server.js`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        Il tuo server WebSocket ora è in
                                        esecuzione e pronto per accettare
                                        connessioni dai client!
                                    </p>
                                    <h5>
                                        Creazione di un'app React con WebSocket
                                    </h5>
                                    <p>
                                        Ora che abbiamo un server WebSocket in
                                        esecuzione, possiamo creare un'app React
                                        che lo utilizzi per la comunicazione in
                                        tempo reale. Iniziamo creando un nuovo
                                        progetto React utilizzando Create React
                                        App.
                                    </p>

                                    <p>
                                        Esegui il seguente comando per creare un
                                        nuovo progetto React:
                                    </p>

                                    <MyCoolCodeBlock
                                        code={`npx create-react-app websocket-react-app
cd websocket-react-app`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        Per utilizzare WebSocket nell'app React,
                                        è necessario installare la libreria
                                        `websocket` utilizzando npm o yarn:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`npm install websocket`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        Per utilizzare WebSocket nell'app React,
                                        è necessario installare la libreria
                                        `websocket` utilizzando npm o yarn:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`npm install websocket`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        Ora che abbiamo configurato il server
                                        WebSocket e creato il progetto React,
                                        possiamo procedere con l'implementazione
                                        del client WebSocket. Creeremo un
                                        componente React chiamato
                                        `WebSocketChat` che consentirà agli
                                        utenti di inviare messaggi al server e
                                        ricevere le risposte in tempo reale.
                                    </p>
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <br />
                                    <p>
                                        Iniziamo creando il nostro componente
                                        `WebSocketChat.js` nella directory
                                        `src`:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
import React, { useState, useEffect } from 'react';

function WebSocketChat() {
    const [message, setMessage] = useState('');
    const [receivedMessage, setReceivedMessage] = useState('');
    const [socket, setSocket] = useState(null);
    
    useEffect(() => {
        // Connessione al server WebSocket quando il componente si monta
        const newSocket = new WebSocket('ws://localhost:8080');
    
        newSocket.onopen = () => {
            console.log('Connected to WebSocket server');
        };
    
        newSocket.onmessage = (event) => {
            const received = event.data;
            setReceivedMessage(received);
        };
    
        setSocket(newSocket);
    
        // Chiudi la connessione WebSocket quando il componente si smonta
        return () => {
            newSocket.close();
        };
    }, []);
    
    const sendMessage = () => {
        if (socket && message) {
            socket.send(message);
            setMessage('');
        }
    };
    
    return (
        <div>
            <h1>WebSocket Chat</h1>
            <div>
                <input
                    type="text"
                    placeholder="Type your message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <button onClick={sendMessage}>Send</button>
            </div>
            <div>
                <p>Received message: {receivedMessage}</p>
            </div>
        </div>
    );
}

export default WebSocketChat;
                                        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        In questo componente, creiamo una
                                        connessione WebSocket quando il
                                        componente si monta e la chiudiamo
                                        quando il componente si smonta. Quando
                                        l'utente invia un messaggio, lo inviamo
                                        al server WebSocket utilizzando il
                                        metodo `send`. I messaggi ricevuti dal
                                        server vengono visualizzati nella parte
                                        inferiore del componente.
                                    </p>
                                    <h5>
                                        Utilizzo del componente WebSocketChat
                                    </h5>
                                    <p>
                                        Ora possiamo utilizzare il componente
                                        `WebSocketChat` nell'app React
                                        principale. Modificando il file
                                        `src/App.js` come segue:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
import React from 'react';
import './App.css';
import WebSocketChat from './WebSocketChat';

function App() {
    return (
        <div className="App">
            <WebSocketChat />
        </div>
    );
}

export default App;
                                        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />

                                    <p>
                                        Ora è possibile avviare l'app React
                                        eseguendo il seguente comando nella
                                        directory del progetto:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`npm start`}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        L'app verrà avviata e puoi aprire il tuo
                                        browser per testare la chat WebSocket in
                                        tempo reale.
                                    </p>
                                    <p>
                                        Si tratta di una tecnologia potente che
                                        può essere utilizzata per una vasta
                                        gamma di applicazioni, dalla chat in
                                        tempo reale ai giochi multiplayer e agli
                                        aggiornamenti live su dati. Utilizzando
                                        WebSocket in combinazione con React, è
                                        possibile creare esperienze utente
                                        altamente interattive e dinamiche!
                                    </p>
                                    <p>
                                        Hai bisogno di implementare una Real
                                        Time Communication all'interno della tua
                                        app? Siamo a disposizione per fornirti
                                        una consulenza e affiancarti nello
                                        sviluppo React.{" "}
                                    </p>
                                    <p>Buon coding!</p>

                                    <br />
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};
export default BlogDetails;
